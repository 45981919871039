// src/components/ApiInitializer.js
import { useEffect } from 'react';
import apiService from './apiService'; // Update the import path if needed
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';

const ApiInitializer = () => {
    const { logout, showSessionExpiredPopup } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const handle401Error = () => {
            logout(); // Logout the user
            showSessionExpiredPopup(); // Show the session expiry popup
            navigate('/'); // Navigate to the homepage
        };

        apiService.init(handle401Error);
    }, [logout, showSessionExpiredPopup, navigate]);

    return null; // This component does not render anything
};

export default ApiInitializer;
