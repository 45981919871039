import React, { useEffect, useCallback } from 'react';

const EventPopup = ({ event, onClose, onEdit, onDelete }) => {
  // Memoize handleClickOutside to prevent unnecessary re-creations
  const handleClickOutside = useCallback((e) => {
    if (event && !e.target.closest('.event-popup')) {
      onClose();
    }
  }, [event, onClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]); // Now the dependency array only contains handleClickOutside

  if (!event) return null;

  return (
    <div className="event-popup" style={{ position: 'absolute', top: event.y, left: event.x }}>
      <p>{event.title}</p>
      <button onClick={onEdit}>Edit</button>
      <button onClick={onDelete}>Delete</button>
    </div>
  );
};

export default EventPopup;
