import React, { useState,useEffect } from 'react';
import { useAuth } from '../components/AuthProvider'; // Correctly re-import useAuth for session management
import { useAuthModal } from '../components/Layout'; // Ensure this path matches the location of Layout.js
import MainContent from '../components/HomePageComponents/MainContent';

const MobileWarningPopup = ({ onClose }) => {
  // Use useEffect to attach an event listener to the overlay
  useEffect(() => {
    const handleClickOutside = (event) => {
      const content = document.querySelector('.mobile-warning-content');
      if (content && !content.contains(event.target)) {
        onClose(); // Close the popup if the click is outside the content
      }
    };
    // Attach the event listener to the document
    document.addEventListener('mousedown', handleClickOutside);
    // Cleanup the event listener on component unmount
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]); // Ensure the effect re-runs only if onClose changes

  return (
    <div className="mobile-warning-overlay">
      <div className="mobile-warning-content">
        <p>Calendair Mobile is still in beta, for best results use a desktop device.</p>
        <button className="warning-close-btn" onClick={onClose}>
          Close 
        </button>      
      </div>
    </div>
  );
};

const SessionExpiryPopup = ({ onClose }) => {
  const { openAuthModal } = useAuthModal();

  // Prevent background scroll when popup is visible
  useEffect(() => {
    // Disable scrolling on the body
    document.body.style.overflow = 'hidden';

    // Cleanup function to re-enable scrolling when the popup closes or component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, []); // Empty dependency array means this effect runs only on mount and cleanup on unmount

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <p>For your security, this session has been signed out. Please sign in again.</p>
        <button onClick={() => {
          onClose(); // This should also hide the popup
          openAuthModal(true); // Open the authentication modal
        }}>
          Sign in
        </button>
      </div>
    </div>
  );
};

const HomePage = () => {
  const { sessionExpiredPopup, hideSessionExpiredPopup } = useAuth(); // Use useAuth for session state management
  const [showMobileWarning, setShowMobileWarning] = useState(false);
  const handleCloseMobileWarning = () => setShowMobileWarning(false);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowMobileWarning(true);
      } else {
        setShowMobileWarning(false);
      }
    };
    // Check on initial load
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="demo">
      {showMobileWarning && <MobileWarningPopup onClose={handleCloseMobileWarning}/>}
      <MainContent/>
      {sessionExpiredPopup && (
        <SessionExpiryPopup onClose={hideSessionExpiredPopup} />
      )}
    </div>
  );
};

export default HomePage;
