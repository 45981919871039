// src/pages/ProtectedCalendarPage.js

import apiService from '../components/apiService';
import React, { useState, useEffect } from 'react';
import CalendarComponent from '../components/Calendar';
import DraggableEvents from '../components/DraggableEvents';
import EventFormPopup from '../components/EventFormPopup';

const ProtectedCalendarPage = () => {
  const [events, setEvents] = useState([]); // State to hold events
  const [calendarEvents, setCalendarEvents] = useState([]); // State to hold calendar events
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State for popup visibility
  const [selectedEventId, setSelectedEventId] = useState(null);

  useEffect(() => {
    fetchEventsFromAPI();
  }, []);

  const fetchEventsFromAPI = async () => {
    try {
        // Fetch todo events
        const todoResponse = await apiService.getTodoEvents();
        const todoEvents = todoResponse.data.todo_events.map(event => ({
            ...event,
            extendedProps: {
                priority: event.priority
            }
        }));
        setEvents(todoEvents);

        // Fetch calendar events
        const calendarResponse = await apiService.getCalendarEvents();
        const calendarEvents = calendarResponse.data.calendar_events.map(event => ({
            ...event,
            extendedProps: {
                priority: event.priority
            }
        }));
        setCalendarEvents(calendarEvents);

    } catch (error) {
        console.error('Error fetching events:', error);
    }
};

  const showAddEventPopup = () => {
    setSelectedEventId(null);
    setIsPopupVisible(true);
  };

  const showEditEventPopup = (eventId) => {
    setSelectedEventId(eventId);
    setIsPopupVisible(true);
  };

  const hideAddEventPopup = () => {
    setIsPopupVisible(false);
  };

  const handleAddOrUpdateEvent = async (e, isEditMode) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updatedEvent = {
        title: formData.get('title'),
        duration: formData.get('duration'),
        startTime: formData.get('startTime'),
        startDate: formData.get('startDate'),
        priority: formData.get('priority')
    };

    if (isEditMode) {
        try {
            // Call API to update the event
            await apiService.updateEvent(selectedEventId, updatedEvent);
            // Fetch updated events list
            
            fetchEventsFromAPI();
        } catch (error) {
            console.error('Error updating event:', error);
            // Handle error (e.g., show a notification to the user)
        }
    } else {
        try {
            // Create new event
            await apiService.createEvent(updatedEvent);
            fetchEventsFromAPI();

        } catch (error) {
            console.error('Error adding event:', error);
            // Handle error (e.g., show a notification to the user)
        }
    }

    hideAddEventPopup();
};


  return (
    <div id="wholepage">
      {isPopupVisible && (
        <EventFormPopup
          onSubmit={handleAddOrUpdateEvent}
          onCancel={hideAddEventPopup}
          eventId={selectedEventId}
        />
      )}
      <DraggableEvents 
        events={events} 
        setEvents={setEvents} 
        onAddEvent={showAddEventPopup} 
        onEditSelectedEvent={showEditEventPopup}
      />
      <CalendarComponent 
        calendarEvents={calendarEvents} 
        refreshEvents={fetchEventsFromAPI}
        onEditEvent={showEditEventPopup}
      />
    </div>
  );
};

export default ProtectedCalendarPage;
