import React from 'react';
// Import the useAuthModal hook from the location of the Layout component.
import { useAuthModal } from '../Layout'; // Adjust the import path as necessary
import '../../css/MainContent.css';
import heroImage from '../../images/heroImage.png';
import scheduleGif from '../../images/scheduleGif.gif';
import dynamicGif from '../../images/dynamicGif.gif';
import todoGif from '../../images/todoGif.gif';
import editGif from '../../images/editGif.gif';
import soonGif from '../../images/soonGif.gif';

const MainContent = () => {
    // Use the useAuthModal hook to get the openAuthModal function
    const { openAuthModal } = useAuthModal();
  
    // Handle click to open the auth modal, assuming false indicates signup mode
    const handleGetStartedClick = () => {
      window.scrollTo(0, 0);
      openAuthModal(false); // Use this for opening in signup mode
    };

  return (
    <main className="container">
      <section className="hero-section">
        <h2>Welcome to Calendair</h2>
        <p>The simple tool for dynamic scheduling.</p>
        <img className="hero-image" src={heroImage} alt="Hero" />
      </section>
      
      <section className="feature-section">
        <div className="feature">
          <h3>More Than Just a Calendar</h3>
          <p>Your to-dos and schedule, perfectly aligned.</p>
          <img src={todoGif} alt="Todo View" className="feature-image"/>
        </div>
      </section>

      <section className="dual-image-section">
        <h3>Ultimate Flexibility</h3>
        <div className="image-grid">
          <div className="grid-item">
            <p>Plan without knowing all of the details.</p>
            <img src={scheduleGif} alt="Create View" className="feature-image"/>
          </div>
          <div className="grid-item">
            <p>Seamlessly reorganize your schedule.</p>
            <img src={dynamicGif} alt="Dynamic View" className="feature-image"/>
          </div>
        </div>
      </section>

      <section className="feature-section">
        <div className="feature">
          <h3>Adapt Instantly</h3>
          <p>One click to adjust, because life is unpredictable.</p>
          <img src={editGif} alt="Edit View" className="feature-image"/>
        </div>

        <div className="soonfeature">
          <h3>Coming Soon...</h3>
          <p>Auto Scheduling: the "ai" in Calendair</p>
          <img src={soonGif} alt="Soon View" className="feature-image"/>
        </div>
      </section>

      <section className="cta-section">
        <h3>Embrace the simplicity of Calendair</h3>
        <button 
          className="cta-button" 
          onClick={handleGetStartedClick}
        >
          Get Started for Free
        </button>
      </section>
    </main>
  );
};

export default MainContent;
