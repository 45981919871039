// src/apiService.js
import axios from 'axios';


let handle401Error; // Callback function for handling 401 errors

const axiosInstance = axios.create({
    withCredentials: true, // Ensure cookies are sent with requests
    headers: {
        'Content-Type': 'application/json' // Set Content-Type to application/json for all requests
    }
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && (error.response.status === 401 || error.response.status === 500) && handle401Error) {
            handle401Error(); // Call the callback when a 401 or 500 error occurs
        }
        return Promise.reject(error);
    }
);

const apiService = {
    init: (on401Error) => {
        handle401Error = on401Error; // Initialize the callback
    },
    handle401: () => {
        if (handle401Error) {
            handle401Error();
        }
    },

    async login(email, password) {
        return axiosInstance.post('/login', { email, password });
    },

    async register(email, password) {
        return axiosInstance.post('/register', { email, password });
    },

    async createEvent(eventData) {
        return axiosInstance.post('/events', eventData);
    },

    async deleteEvents(eventIds) {
        // If it's a single event, send as 'event_id', otherwise as 'event_ids'
        const data = eventIds.length === 1 
            ? { event_id: eventIds[0] } 
            : { event_ids: eventIds };
    
        return axiosInstance.post('/events/delete', data);
    },

    async updateEvent(eventId, eventData) {
        return axiosInstance.put(`/events/${eventId}`, eventData);
    },

    async getTodoEvents() {
        return axiosInstance.get('/todo_events');
    },

    async getCalendarEvents() {
        return axiosInstance.get('/calendar_events');
    },

    async dragEvent(eventId, eventData) {
        return axiosInstance.put(`/events/drag/${eventId}`, eventData);
    },

    async getSingleEvent(eventId) {
        return axiosInstance.get(`/event/${eventId}`);
    },
    
    // ... other API methods ...
};

export default apiService;
