// src/components/AuthProvider.js
import React, { useState, createContext, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [sessionExpiredPopup, setSessionExpiredPopup] = useState(false);
    const [navigateToCalendar, setNavigateToCalendar] = useState(true);



    const showSessionExpiredPopup = () => {
        setSessionExpiredPopup(true); // Method to show session expiry popup
    };

    const hideSessionExpiredPopup = () => {
        setSessionExpiredPopup(false); // Method to hide session expiry popup
    };

    const login = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
    };

    const logout = async () => {
        try {
            await axios.post('/logout', {});
            setUser(null);
            localStorage.removeItem('user');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const preventAutoNavigate = () => {
        setNavigateToCalendar(false);
    };

    useEffect(() => {
        // Check if a user is already logged in and set navigateToCalendar accordingly
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
            setNavigateToCalendar(true);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ 
            user, 
            login, 
            logout, 
            sessionExpiredPopup, 
            showSessionExpiredPopup, 
            hideSessionExpiredPopup,
            navigateToCalendar, // Include this in the context
            preventAutoNavigate // Include this new method in the context
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
