import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../components/AuthProvider'; // Import useAuth
import { useNavigate } from 'react-router-dom';
import apiService from '../components/apiService';


import '../css/AuthModal.css';

Modal.setAppElement('#root');

const AuthModal = ({ isOpen, isLogin, setIsLogin, onRequestClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [confirmpasswordFocused, setConfirmPasswordFocused] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [registrationError, setRegistrationError] = useState('');
  const { login } = useAuth(); // Use the login function from AuthProvider
  const navigate = useNavigate(); // Initialize useNavigate

  
  const handleLoginSuccess = (userData) => {
    login(userData); // Call login with the user data
    onRequestClose(); // Close the modal
    navigate('/calendar');
};

  const validateEmail = (email) => {
    if (email === '') return false;
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return re.test(password);
  };

  const getBorderColor = (fieldValue, fieldError, fieldFocused) => {
    if (fieldFocused) {
      return fieldError ? 'red' : 'blue';
    }
    return fieldError ? 'red' : 'black';
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setSubmitClicked(true);
    setEmailError('');
    setPasswordError('');
    setLoginError('');

    let isValid = true;

    if (!validateEmail(email)) {
      setEmailError('Invalid email address.');
      isValid = false;
    }

    if (!validatePassword(password)) {
      setPasswordError('Your password must be at least 8 characters and contain a number and a special character.');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const response = await apiService.login(email, password);
      if (response.data.success) {
        handleLoginSuccess(response.data.user); // Pass the user data to handleLoginSuccess
      }
    } catch (error) {
      if (error.response) {
        // Extract the error message from the API response
        const errorMsg = error.response.data.error;
        
        if (errorMsg === 'invalid email/password') {
          setLoginError('Invalid email/password.');

        } else if (errorMsg === 'unverified email'){
          setLoginError('A verification link has been sent to your email (check spam).');
        } else {
          // Handle other unforeseen errors
          setRegistrationError('Registration failed. Please try again.');
        }
      } else{
        console.error('Login failed:', error.message);
      }
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setSubmitClicked(true);
    setEmailError('');
    setPasswordError('');
    setRegistrationError('');

    let isValid = true;

    if (!validateEmail(email)) {
      setEmailError('Invalid email address.');
      isValid = false;
    }

    if (!validatePassword(password)) {
      setPasswordError('Your password must be at least 8 characters and contain a number and a special character.');
      isValid = false;
    }
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const response = await apiService.register(email, password);
      if (response.data.success) {
        setIsLogin(true); // Switch to login modal
        handleLogin(event); // Attempt auto-login
      }
    } catch (error) {
      if (error.response) {
        // Extract the error message from the API response
        const errorMsg = error.response.data.error;
  
        // Handle different types of errors
        if (errorMsg === 'email already in use') {
          setRegistrationError('Email already in use.');
        } 
        else if (errorMsg === 'invalid email') {
          setRegistrationError('Invalid email.');
        } else if (errorMsg === 'resend verify') {
          setRegistrationError('Email already in use, but unverified. A new link has been sent')
          setIsLogin(true); // Switch to login modal
          handleLogin(event); // Attempt auto-login
        } else {
          // Handle other unforeseen errors
          setRegistrationError('Registration failed. Please try again.');
        }
      } else {
        // Handle errors not related to the API response
        console.error('Registration failed:', error.message);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Auth Modal" className="auth-modal" overlayClassName="react-modal-overlay" // Apply custom overlay class
    >      
    {isLogin ? (
        <div>
          <h2>Log in</h2>
          {loginError && <p className="error">{loginError}</p>}
          <form onSubmit={handleLogin}>
            <input
              type="text" name = "username"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setEmailFocused(true)}
              onBlur={() => setEmailFocused(false)}
              style={{ borderColor: getBorderColor(email, emailError, emailFocused) }}
            />
            {submitClicked && emailError && <p className="error">{emailError}</p>}
            <input
              type="password" name = "password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setPasswordFocused(true)}
              onBlur={() => setPasswordFocused(false)}
              style={{ borderColor: getBorderColor(password, passwordError, passwordFocused) }}
            />
            {submitClicked && passwordError && <p className="error">{passwordError}</p>}
            <button type="submit">Sign in</button>
          </form>
          <button className = "switching" onClick={() => setIsLogin(false)}>Need an account? Register</button>
        </div>
      ) : (
        <div>
          <h2>Register</h2>
          <form onSubmit={handleRegister}>
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setEmailFocused(true)}
              onBlur={() => setEmailFocused(false)}
              style={{ borderColor: getBorderColor(email, emailError, emailFocused) }}
            />
            {submitClicked && emailError && <p className="error">{emailError}</p>}
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setPasswordFocused(true)}
              onBlur={() => setPasswordFocused(false)}
              style={{ borderColor: getBorderColor(password, passwordError, passwordFocused) }}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onFocus={() => setConfirmPasswordFocused(true)}
              onBlur={() => setConfirmPasswordFocused(false)}
              style={{ borderColor: getBorderColor(confirmPassword, passwordError, confirmpasswordFocused) }}
            />
            {submitClicked && passwordError && <p className="error">{passwordError}</p>}
            {registrationError && <p className="error">{registrationError}</p>}
            <button type="submit">Sign Up</button>
          </form>
          <button className="switching" onClick={() => setIsLogin(true)}>Have an account? Sign in</button>
        </div>
      )}
    </Modal>
  );
};

export default AuthModal;
