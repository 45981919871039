import React, { useState, useEffect } from 'react';
import apiService from './apiService'; // Adjust the import path as needed
import '../css/EventFormPopup.css';

const EventFormPopup = ({ onSubmit, onCancel, eventId }) => {
    const [eventData, setEventData] = useState(null);

    useEffect(() => {
        const fetchEvent = async () => {
            if (eventId) {
                try {
                    const response = await apiService.getSingleEvent(eventId);
                    setEventData(response.data.event);
                    //console.log(response.data.event.priority);
                } catch (error) {
                    console.error('Error fetching event:', error);
                }
            }
        };

        fetchEvent();
    }, [eventId]);

    const isEditMode = !!eventId;


    const validateDuration = (duration) => {
        if (duration === '') return true;
        const durationPattern = /^([0-9]{1,4}|[0-8][0-7][0-6][0-0]):([0-5][0-9])$/;
        return durationPattern.test(duration);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
    
        // Use formData to get the values
        const title = formData.get('title');
        const duration = formData.get('duration');
        const startTime = formData.get('startTime');
        const startDate = formData.get('startDate');
        const priority = formData.get('priority');
    
        if (duration && !validateDuration(duration)) {
            alert('Duration must be in hh:mm format, hours up to 8760, minutes up to 59.');
            return;
        }
    
        const updatedEventData = {
            title,
            duration,
            startTime,
            startDate,
            priority,
        };
    
        onSubmit(e, isEditMode, updatedEventData);
    };
    
    const handleSelectChange = (e) => {
        setEventData({ ...eventData, priority: e.target.value });
    };

    return (
        <div className="popup">
            <form onSubmit={(e) => handleSubmit(e, isEditMode)}>
                <input 
                    type="text" 
                    name="title" 
                    title="Event name (required)" 
                    placeholder="Event Name" 
                    defaultValue={eventData ? eventData.title : ''} 
                    required 
                />
                <input 
                    type="text" 
                    name="duration" 
                    placeholder="Duration (e.g., '02:00')" 
                    defaultValue={eventData ? eventData.duration : ''} 
                />
                <input 
                    type="time" 
                    name="startTime" 
                    placeholder="Start time (e.g., '02:00')" 
                    defaultValue={eventData && eventData.start_time ? eventData.start_time.split('T')[1].slice(0, 5) : ''} 
                />
                <input 
                    type="date" 
                    name="startDate" 
                    defaultValue={eventData && eventData.start_day ? eventData.start_day : ''} 
                />
                <select 
                    name="priority" 
                    value={eventData ? eventData.priority : 'none'}
                    onChange={handleSelectChange}
                >
                    <option value="none">No Priority</option>
                    <option value="low">!</option>
                    <option value="mid">!!</option>
                    <option value="high">!!!</option>
                </select>
                <button type="submit">{isEditMode ? 'Update Event' : 'Add Event'}</button>
                <button type="button" onClick={onCancel}>Cancel</button>
            </form>
        </div>
    );
};

export default EventFormPopup;