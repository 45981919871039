import React, { useState, useRef, useEffect, createContext, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import pfp1 from '../images/pfp.png';
import pfp from '../images/pfp1.png';
import AuthModal from './AuthModal';
import '../css/Layout.css'; // Adjust the path according to your file structure

// Context creation for auth modal control
const AuthModalContext = createContext();

// Custom hook for easy context consumption
export const useAuthModal = () => useContext(AuthModalContext);

const Layout = ({ children }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [authModalIsOpen, setAuthModalIsOpen] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const popupRef = useRef();
  const buttonRef = useRef();
  const { user, logout, preventAutoNavigate } = useAuth();
  const location = useLocation();

  const openAuthModalWithContext = (value) => {
    setIsLogin(value);
    setPopupIsOpen(false);
    setAuthModalIsOpen(true);
  };

  const closeAuthModal = () => setAuthModalIsOpen(false);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target) && 
        !buttonRef.current.contains(event.target)) {
      setPopupIsOpen(false);
    }
  };

  const handleLogoClick = () => {
    preventAutoNavigate(); // Prevent auto-navigate to /calendar
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <AuthModalContext.Provider value={{ openAuthModal: openAuthModalWithContext, closeAuthModal }}>
      <div>
        <header className="layheader">
          <Link to="/" className="textLogo" onClick={handleLogoClick}>
            <h1>Calendair</h1>
          </Link>
              {/* Conditionally render the Start Planning button if on the homepage */}
          {location.pathname === '/' && (
            <button
              className="startPlanningButton"
              onClick={() => user ? window.location.href = '/calendar' : openAuthModalWithContext(true)}
            >
              Start Planning
            </button>
          )}
          
          <button className="profileButton" onClick={() => setPopupIsOpen(!popupIsOpen)} ref={buttonRef}>
            {user ? <img src={pfp} alt="Profile" className="profileImage" /> : <img src={pfp1} alt="Profile" className="profileImage" />}
          </button>

          {popupIsOpen && (
            user ? (
              <div className="profilePopup" ref={popupRef}>
                <button onClick={logout}>Logout</button>
              </div>
            ) : (
              <div className="profilePopup" ref={popupRef}>
                <button onClick={() => openAuthModalWithContext(true)}>Log In</button>
                <button type="signup" onClick={() => openAuthModalWithContext(false)}>Sign Up</button>
              </div>
            )
          )}
        </header>
        <main>
          {children}
        </main>
        <AuthModal 
          isOpen={authModalIsOpen} 
          isLogin={isLogin} 
          setIsLogin={setIsLogin} 
          onRequestClose={closeAuthModal}
        />
      </div>
    </AuthModalContext.Provider>
  );
};

export default Layout;
