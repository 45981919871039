import React, {useEffect, useState } from 'react';
import apiService from './apiService';

const DraggableEvents = ({ events, setEvents, onAddEvent, onEditSelectedEvent }) => {

    const completeEvent = async (e, eventId) => {
        e.stopPropagation(); // Prevent triggering the event's main onClick

        try {
            // Call API to delete the event
            await apiService.deleteEvents([eventId]);
            // Update the local state to remove the deleted event
            setEvents(prevEvents => prevEvents.filter(event => event.id !== eventId));
        } catch (error) {
            console.error('Error deleting event:', error);
            // Handle the error appropriately (e.g., show a notification to the user)
        }
    }; 

    const [sortedEvents, setSortedEvents] = useState([]);
    useEffect(() => {
        const priorityLevels = {
            'none': 0,
            'low': 1,
            'mid': 2,
            'high': 3
        };

        const getPriorityValue = (priorityString) => {
            return priorityLevels[priorityString] || 0;
        };

        const sorted = [...events].sort((a, b) => getPriorityValue(b.priority) - getPriorityValue(a.priority));
        setSortedEvents(sorted);
    }, [events]);


    const toggleSelection = (id, e) => {
        setEvents((prevEvents) => {
            return prevEvents.map((event) => {
                if (event.id === id) {
                    return { ...event, selected: !event.selected };
                } else {
                    return { ...event, selected: e.shiftKey && event.selected };
                }
            });
        });
    };

    const deleteSelectedEvents = async () => {
        const selectedEventIds = events.filter(event => event.selected).map(event => event.id);
        try {
            if (selectedEventIds.length > 0) {
                await apiService.deleteEvents(selectedEventIds);
                // Update the local state to remove the deleted events
                setEvents(prevEvents => prevEvents.filter(event => !event.selected));
            }
        } catch (error) {
            console.error('Error deleting events:', error);
            // Handle the error appropriately (e.g., show a notification to the user)
        }
    };

    const handleEditSelectedEvent = () => {
        const selectedEvent = events.find(event => event.selected);
        if (selectedEvent) {
            onEditSelectedEvent(selectedEvent.id);
        }
    };

    function handleMouseDown(e) {
        
        e.preventDefault();
        const el = document.getElementById('external-events');
        const offsetX = e.clientX - el.getBoundingClientRect().left;
        const offsetY = e.clientY - el.getBoundingClientRect().top;

        function onMouseMove(ev) {
            el.style.left = (ev.clientX - offsetX) + 'px';
            el.style.top = (ev.clientY - offsetY) + 'px';
        }

        function onMouseUp() {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        }

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    }
  
    return (
        <div id="external-events">
            <div className="header" onMouseDown={handleMouseDown}>
                <div className="buttons">
                    <button className="add-button" onClick={onAddEvent} title="Create new event"></button>
                    <button className="edit-button" onClick={handleEditSelectedEvent} title="Edit selected"></button>
                    <button className="delete-button" onClick={deleteSelectedEvents} title="Delete selected"></button>
                    <button className="schedule-button" title="COMING SOON- Smart schedule selected event(s)"></button>
                </div>
            </div>
            {sortedEvents.map(event => (
            <div
                key={event.id}
                className={`draggable-event ${event.selected ? 'selected' : ''} priority-${event.priority}`}
                data-event={JSON.stringify({ 
                    id: event.id,
                    title: event.title, 
                    duration: event.duration, 
                    allDay: event.allDay, 
                    start: event.startTime,
                })}

                onClick={(e) => toggleSelection(event.id, e)}
            >
                {event.title}
                <span className="priority-dot" onClick={(e) => completeEvent(e, event.id)}></span>
            </div>
        ))}

        </div>
    );
};

export default DraggableEvents;