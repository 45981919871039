import React, { useRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list'; 
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import apiService from './apiService';
import EventPopup from './EventPopup'; // Import the EventPopup component

import '../css/calendar.css';

const CalendarComponent = ({ calendarEvents, refreshEvents,onEditEvent }) => { // Accept calendarEvents as a prop
  const calendarRef = useRef(null);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [showPopup, setShowPopup] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  

  const handleEventDrag = async (info) => {
    try {
      const eventData = {
        newStart: info.event.start,
        isAllDay: info.event.allDay,
        timeZone: timeZone,
        monthView: false,
      };
      
      await apiService.dragEvent(info.oldEvent.id,eventData);
      refreshEvents();
    } 
    catch (error) {
      console.error('Error editing event on drag:', error);
    }
  };


  const handleEventReceive = async (info) => {
    try {
        // Parsing the data-event attribute to get event details
        const eventDetails = JSON.parse(info.draggedEl.getAttribute('data-event'));
        const draggedEventId = eventDetails.id; // Retrieve the id from the event details

        const monthView = calendarRef.current.getApi().view.type === 'dayGridMonth';


        // Check if the original event had a start time
        console.log(eventDetails.startTime);
        const isAllDay = info.event.allDay; // This should be true if dropped on an all-day slot

        // Construct the event data with the new start date
        const eventData = {
            newStart: info.event.start.toISOString(), // Assuming you need an ISO string
            isAllDay: isAllDay,
            timeZone: timeZone,
            monthView: monthView,
        };

        // Call the API to update the event
        await apiService.dragEvent(draggedEventId, eventData);
        
        // Refresh events
        refreshEvents();
    } catch (error) {
        console.error('Error handling event on drop:', error);
    }
};
  

  useEffect(() => {
    let draggableEl = document.getElementById('external-events');
    let draggable = new Draggable(draggableEl, {
      itemSelector: '.draggable-event',
      eventData: function(eventEl) {
        try {
          let event = JSON.parse(eventEl.getAttribute('data-event'));
          return {
            title: event.title,
            id: event.id,
            start: event.start,
            end: event.end,
            allDay: event.allDay,
            create: true,
          };
        } catch (error) {
          console.error("Error parsing event data:", error);
          return {};
        }
      }
    });
    return () => draggable.destroy();
  }, []);

  const handleEventClick = (info) => {
    const rect = info.el.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const bottomY = rect.bottom; // Align with the bottom of the event element
  
    setSelectedEvent({ 
      id: info.event.id,
      x: centerX, 
      y: bottomY,
      width: rect.width // Set width to match the event element
    });
    setShowPopup(true);
  };
  

  const handleEdit = () => {
    // Logic to handle edit
    setShowPopup(false);
    if (selectedEvent) {
      onEditEvent(selectedEvent.id);
    }
    // Here you'll need to call the function to show your EventFormPopup with selectedEvent
  };

  const handleDelete = async () => {
    // Check if an event is selected
    if (selectedEvent && selectedEvent.id) {
        try {
            // Call the API to delete the event
            await apiService.deleteEvents([selectedEvent.id]); // Pass the id in an array
            // Refresh the events to update the calendar view
            refreshEvents();
        } catch (error) {
            console.error('Error deleting event:', error);
            // Optionally, handle the error (e.g., show a notification to the user)
        }
    } else {
        console.warn('No event selected for deletion');
        // Optionally, handle the case when no event is selected (e.g., show a message to the user)
    }
    setShowPopup(false); // Hide the popup after attempting to delete
};

const priorityMapping = {
  'high': 1,
  'mid': 2,
  'low': 3,
  'none': 4
};

const getNumericPriority = (priority) => {
  return priorityMapping[priority] || 5; // Default for unrecognized priorities
};

const customEventSort = (eventA, eventB) => {
  // All-day events first
  if (eventA.allDay && !eventB.allDay) return -1;
  if (!eventA.allDay && eventB.allDay) return 1;

  // If both are all-day or timed events, sort by start time
  if (eventA.start < eventB.start) return -1;
  if (eventA.start > eventB.start) return 1;

  // If start times are the same, sort by priority
  const priorityA = getNumericPriority(eventA.extendedProps.priority);
  const priorityB = getNumericPriority(eventB.extendedProps.priority);
  if (priorityA !== priorityB) return priorityA - priorityB;

  // If priorities are the same, sort by title
  if (eventA.title < eventB.title) return -1;
  if (eventA.title > eventB.title) return 1;

  return 0; // If all else is equal, maintain existing order
};


  return (
    <div className="calendar-container">
      <FullCalendar
        aspectRatio = {1.5}
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,listWeek'
        }}
    
        editable={true}
        droppable={true}
        events={calendarEvents}
        eventOrder={customEventSort}
        eventOrderStrict={true}
        nextDayThreshold= {'02:00:00'}
  
        //displayEventEnd = {true}

        eventDrop={handleEventDrag}
        eventReceive={handleEventReceive} 
        eventClick = {handleEventClick}
      />
      {showPopup && 
        <EventPopup 
          event={selectedEvent}
          onClose={() => setShowPopup(false)}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      }
    </div>
  );
};

export default CalendarComponent;
