// src/components/AutoRedirect.js
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const AutoRedirect = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, navigateToCalendar, preventAutoNavigate } = useAuth();

    useEffect(() => {
        // Redirect to /calendar if the user is authenticated and navigateToCalendar is true
        if (user && navigateToCalendar) {
            navigate('/calendar');
        }

        // Disable auto-redirect when on the homepage
        if (location.pathname === '/') {
            preventAutoNavigate(false);
        }
    }, [user, navigateToCalendar, preventAutoNavigate, navigate, location.pathname]);

    return null; // This component does not render anything
};

export default AutoRedirect;
