import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthProvider';
import ApiInitializer from './components/ApiInitializer';
import HomePage from './pages/HomePage';
import ProtectedCalendarPage from './pages/ProtectedCalendarPage';
import ProtectedRoute from './components/ProtectedRoute';
import './css/HomePage.css';
import Layout from './components/Layout';
import AutoRedirect from './components/AutoRedirect'; // Import the new component


function App() {
  return (
    <AuthProvider>
      <Router>
        <ApiInitializer />
        <AutoRedirect/>
        <Routes> {/* Use Routes to wrap Route components */}
          <Route 
            path="/" 
            element={
            <Layout>
            <HomePage />
            </Layout>} 
          />
          <Route 
            path="/calendar" 
            element={
              <ProtectedRoute>
                <Layout>
                <ProtectedCalendarPage /> {/* This will be rendered if the user is authenticated */}
                </Layout>
              </ProtectedRoute>
            } 
          />
          {/* Add other routes as needed */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
